import axios from 'axios'
export default {
    state: {
        itensReport: [],
    },
    mutations: {
        setItensReport(state, payload) {
            state.itensReport = payload;
        },
        setFuncionariosItemsReport(state, payload) {
            state.funcionariosItemsReport = payload;
        },
    },
    actions: {
        setItensReport(context, payload) {
            context.commit('setItensReport', payload);
        },
        loadItensReport(context, payload) {
            const query = context.getters.getUtils.queryByObject(payload.filter);
            const callback = typeof payload.callback == 'function' ? payload.callback : null;
            if(payload.callback) delete payload.callback;
            axios(`/report/${payload.route}/${query}`).then((resp) => {
                context.commit('setItensReport', resp.data);
                if(callback) callback(resp.data);
            });
        },
        loadFuncionariosItemsReport(context, payload) {
            const query = context.getters.getUtils.queryByObject(payload.filter);
            const callback = typeof payload.callback == 'function' ? payload.callback : null;
            if(payload.callback) delete payload.callback;
            axios(`/report/${payload.route}/${query}`).then((resp) => {
                context.commit('setFuncionariosItemsReport', resp.data);
                if(callback) callback(resp.data);
                console.log(resp.data);
            });
        },
    },
    getters: {
        getItensReport(state) {
            return state.itensReport
        },
        getFuncionariosItemsReport(state) {
            return state.funcionariosItemsReport
        },
    },
}