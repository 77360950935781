import axios from 'axios'
export default {
    state: {
        planos: [],
    },
    mutations: {
        setPlanos(state, payload) {
            state.planos = payload;
        },
    },
    actions: {
        setPlanos(context, payload) {
            context.commit('setPlanos', payload);
        },
        loadPlanos(context) {
            axios(`plano`).then((resp) => {
                if (resp.status < 399) {
                    context.commit('setPlanos', resp.data);
                }
            });
        },
        savePlano(context, playload) {
            // limpa o callback para não dar erro no axios
            const callback = typeof playload.callback == 'function' ? playload.callback : null;
            if(playload.callback) delete playload.callback;
            const metodo = playload.id ? "put" : "post";
            const finalUrl = playload.id ? `/${playload.id}` : "";
            axios[metodo](`/plano${finalUrl}`, playload).then((resp) => {
                if (resp.status < 399) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Plano salvo com Sucesso");
                    context.dispatch('loadPlanos');
                    if(callback) callback(resp.data);
                }
            });
        },
    },
    getters: {
        getPlanos(state) {
            return state.planos
        },
        getPlanoNameById: (state) => (id) => {
            if (!state.planos) {
                return id
            }
            let gp = state.planos.find(g =>
                g.id == id
            )
            return gp ? `${gp.id} - ${gp.name}` : ''
        },
        getPlanoById: (state) => (id) => {
            if (!state.planos) {
                return id
            }
            let gp = state.planos.find(g =>
                g.id == id
            )
            return gp || null;
        },
        getPlanoSelect: (state) => {
            let gp = []
            state.planos.forEach(element => {
                gp.push({ value: element.id, title: `${element.id} - ${element.name}` })
            });
            return gp
        },
    },
}