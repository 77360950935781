import axios from 'axios'
export default {
    state: {
        funcionarios: [],
        funcionariosFilter: "",
        neighborhoodAndCities: {
            neighborhood: [],
            city: []
        },
    },
    mutations: {
        setFuncionarios(state, payload) {
            state.funcionarios = payload;
        },
        setFuncionariosFilter(state, payload) {
            state.funcionariosFilter = payload;
        },
        setNeighborhoodAndCities(state, payload) {
            state.neighborhoodAndCities = payload;
        },
    },
    actions: {
        setFuncionarios(context, payload) {
            context.commit('setFuncionarios', payload);
        },
        setFuncionariosFilter(context, payload) {
            context.commit('setFuncionariosFilter', payload);
        },
        setNeighborhoodAndCities(context, payload) {
            context.commit('setNeighborhoodAndCities', payload);
        },
        loadFuncionarios(context) {
            axios(`funcionarios`).then((resp) => {
                if (resp.status < 399) {
                    context.commit('setFuncionarios', resp.data);
                }
            });
        },
        loadFuncionarioById(context, payload) {
            // limpa o callback para não dar erro no axios
            const callback = typeof payload.callback == 'function' ? payload.callback : null;
            if(payload.callback) delete payload.callback;
            axios(`funcionarios/${payload.id}`).then((resp) => {
                if (resp.status < 399) {
                    let idx = context.state.funcionarios.findIndex((el) => el.id == resp.data.id);
                    if(idx >= 0) {
                        context.state.funcionarios[idx] = resp.data;
                    }
                    if(callback) callback(resp.data);
                }
            });
        },
        loadNeighborhoodAndCities(context) {
            axios(`funcionarios/neighborhoodAndCities`).then((resp) => {
                if (resp.status < 399) {
                    context.commit('setNeighborhoodAndCities', resp.data);
                }
            });
        },
        saveFuncionario(context, playload) {
            // limpa o callback para não dar erro no axios
            const callback = typeof playload.callback == 'function' ? playload.callback : null;
            if(playload.callback) delete playload.callback;
            const metodo = playload.id ? "put" : "post";
            const finalUrl = playload.id ? `/${playload.id}` : "";
            axios[metodo](`/funcionarios${finalUrl}`, playload).then((resp) => {
                if (resp.status < 399) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Funcionario salvo com Sucesso");
                    context.dispatch('loadFuncionarios');
                    if(callback) callback(resp.data);
                }
            });
        },
    },
    getters: {
        getFuncionarios(state) {
            return state.funcionarios
        },
        getNeighborhoodAndCities(state) {
            return state.neighborhoodAndCities
        },
        getFuncionariosFilter(state) {
            if(!state.funcionariosFilter) return state.funcionarios;
            const filterModelStr = (str = "", search = "") => str.toLowerCase().includes(search.toLowerCase());
            // filtro por texto
            let ff = state.funcionarios.filter((cam) => {
                return filterModelStr(cam.name, state.funcionariosFilter)
                    || filterModelStr(cam.cpf, state.funcionariosFilter)
                    || filterModelStr(cam.registration, state.funcionariosFilter);
            });
            return ff
        },
        getFuncionarioNameById: (state) => (id) => {
            if (!state.funcionarios) {
                return id
            }
            let gp = state.funcionarios.find(g =>
                g.id == id
            )
            return gp ? `${gp.id} - ${gp.name}` : ''
        },
        getFuncionarioById: (state) => (id) => {
            if (!state.funcionarios) {
                return id
            }
            let gp = state.funcionarios.find(g =>
                g.id == id
            )
            return gp || null;
        },
        getFuncionarioSelect: (state) => {
            let gp = []
            state.funcionarios.forEach(element => {
                gp.push({ value: element.id, title: `${element.id} - ${element.name}` })
            });
            return gp
        },
    },
}