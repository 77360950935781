import store from '../store/index'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
        meta: { requiresAuth: false }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "Login" */ "../views/Login.vue"),
    },
    {
        path: '/adm',
        name: 'Admin',
        component: () => import(/* webpackChunkName: "Admin" */ "../views/Admin/AdminPage.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/userlevels',
        name: 'UserLevels',
        component: () =>
            import(/* webpackChunkName: "UserLevels" */ "../views/Admin/Niveis.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/userresources',
        name: 'UserResources',
        component: () =>
            import(/* webpackChunkName: "UserResources" */ "../views/Admin/Recursos.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/userpermission',
        name: 'UserPermission',
        component: () =>
            import(/* webpackChunkName: "UserPermission" */ "../views/Admin/Permissoes.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/grupo',
        name: 'Grupo',
        component: () =>
            import(/* webpackChunkName: "Grupo" */ "../views/Admin/Grupo.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/request',
        name: 'Request',
        component: () =>
            import(/* webpackChunkName: "Request" */ "../views/Request.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/log',
        name: 'Log',
        component: () =>
            import(/* webpackChunkName: "Log" */ "../views/Log.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/user',
        name: 'User',
        component: () =>
            import(/* webpackChunkName: "User" */ "../views/Admin/Usuarios.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/logout',
        name: 'Logout',
        component: () =>
            import(/* webpackChunkName: "Logout" */ "../views/Logout.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/routecreator',
        name: 'RouteCreator',
        component: () =>
            import(/* webpackChunkName: "RouteCreator" */ "../views/RoutesCreator.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/usuario',
        name: 'Editar Usuario',
        component: () =>
            import(/* webpackChunkName: "Moradores" */ "../views/editUsuario.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/funcionario',
        name: 'Funcionario',
        component: () =>
            import(/* webpackChunkName: "Funcionario" */ "../views/Funcionario/Funcionario.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/funcionarioForm/:id?',
        name: 'FuncionarioForm',
        component: () =>
            import(/* webpackChunkName: "FuncionarioForm" */ "../views/Funcionario/FuncionarioForm.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/items-categorias',
        name: 'ItemsCategorias',
        component: () =>
            import(/* webpackChunkName: "ItemsCategorias" */ "../views/ItemsCategoria/ItemsCategoria.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/relatorios',
        name: 'Relatorios',
        component: () =>
            import(/* webpackChunkName: "RelatorioView" */ "../views/Relatorios/RelatorioView.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/envio-wpp',
        name: 'EnvioWpp',
        component: () =>
            import(/* webpackChunkName: "EnvioWP" */ "../views/Relatorios/EnvioWP.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/envio-wpp-ext',
        name: 'EnvioWppBaseExterna',
        component: () =>
            import(/* webpackChunkName: "EnvioWPBaseExterna" */ "../views/Relatorios/EnvioWPBaseExterna.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/envio-wpp-upload',
        name: 'EnvioWppUpload',
        component: () =>
            import(/* webpackChunkName: "EnvioWPUpload" */ "../views/Relatorios/EnvioWPUpload.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/comunicador',
        name: 'Comunicador',
        component: () =>
            import(/* webpackChunkName: "Comunicador" */ "../views/Comunicador.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/financeiro',
        name: 'Financeiro',
        component: () =>
            import(/* webpackChunkName: "Financeiro" */ "../views/Financeiro/Financeiro.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/ajuda',
        name: 'Manual',
        component: () =>
            import(/* webpackChunkName: "Manual" */ "../views/Manual/Manual.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () =>
            import(/* webpackChunkName: "NotFound" */ "../views/NotFound.vue"),
        meta: { requiresAuth: false }
    },
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    let logged = store.getters.getLogged
    document.title = `${process.env.VUE_APP_TITLE} - ${to.name}`.toUpperCase();
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (logged) {
            next()
        } else {
            next({ path: '/login' })
        }
    } else {
        next()
    }

})

export default router