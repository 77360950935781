import axios from 'axios'
export default {
    state: {
        numeros: [],
        user_numeros: [],
        campaigns: [],
    },
    mutations: {
        setNumeros(state, payload) {
            state.numeros = payload;
        },
        setUserNumeros(state, payload) {
            state.user_numeros = payload;
        },
        setCampaign(state, payload) {
            state.campaigns = payload;
        },
    },
    actions: {
        setNumeros(context, payload) {
            context.commit('setNumeros', payload);
        },
        setUserNumeros(context, payload) {
            context.commit('setUserNumeros', payload);
        },
        setCampaign(context, payload) {
            context.commit('setCampaign', payload);
        },
        loadNumeros(context) {
            axios(`whatsapp/numeros`).then((resp) => {
                if (resp.status < 399) {
                    context.commit('setNumeros', resp.data);
                }
            });
        },
        loadUserNumeros(context) {
            axios(`whatsapp/user`).then((resp) => {
                if (resp.status < 399) {
                    context.commit('setUserNumeros', resp.data);
                }
            });
        },
        loadCampaign(context) {
            axios(`whatsapp/campaign`).then((resp) => {
                if (resp.status < 399) {
                    context.commit('setCampaign', resp.data);
                }
            });
        },
        saveNumero(context, payload) {
            // limpa o callback para não dar erro no axios
            const callback = typeof payload.callback == 'function' ? payload.callback : null;
            if(payload.callback) delete payload.callback;
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/whatsapp/numeros${finalUrl}`, payload).then((resp) => {
                if (resp.status < 399) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Número salvo com Sucesso");
                    context.dispatch('loadNumeros');
                    if(callback) callback(resp.data);
                }
            });
        },
        saveUserNumero(context, payload) {
            // limpa o callback para não dar erro no axios
            const callback = typeof payload.callback == 'function' ? payload.callback : null;
            if(payload.callback) delete payload.callback;
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/whatsapp/user${finalUrl}`, payload).then((resp) => {
                if (resp.status < 399) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Número vinculado com Sucesso");
                    context.dispatch('loadUserNumeros');
                    if(callback) callback(resp.data);
                }
            });
        },
        saveCampaign(context, payload) {
            // limpa o callback para não dar erro no axios
            const callback = typeof payload.callback == 'function' ? payload.callback : null;
            if(payload.callback) delete payload.callback;
            const metodo = payload.id ? "putForm" : "postForm";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/whatsapp/campaign${finalUrl}`, payload).then((resp) => {
                if (resp.status < 399) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Campanha cadastrada com Sucesso");
                    context.dispatch('loadCampaign');
                    if(callback) callback(resp.data);
                }
            });
        },
        deletNumero(context, payload) {
            const callback = typeof payload.callback == 'function' ? payload.callback : null;
            if(payload.callback) delete payload.callback;
            axios.delete(`whatsapp/numeros/${payload.id}`).then((resp) => {
                if(resp.status < 399){
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Número deletado com Sucesso");
                context.dispatch('loadNumeros');
                if(callback) callback(resp.data);
                }
            });
        },
        deletUserNumero(context, payload) {
            const callback = typeof payload.callback == 'function' ? payload.callback : null;
            if(payload.callback) delete payload.callback;
            axios.delete(`whatsapp/user/${payload.id}`).then((resp) => {
                if(resp.status < 399){
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Número desvinculado com Sucesso");
                context.dispatch('loadUserNumeros');
                if(callback) callback(resp.data);
                }
            });
        },
        deletCampaign(context, payload) {
            const callback = typeof payload.callback == 'function' ? payload.callback : null;
            if(payload.callback) delete payload.callback;
            axios.delete(`whatsapp/campaign/${payload.id}`).then((resp) => {
                if(resp.status < 399){
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Campanha deletada com Sucesso");
                context.dispatch('loadCampaign');
                if(callback) callback(resp.data);
                }
            });
        },
    },
    getters: {
        getNumeros(state) {
            return state.numeros
        },
        getUserNumeros(state) {
            return state.user_numeros
        },
        getCampaign(state) {
            return state.campaigns
        },
        getNumberNameById: (state) => (id) => {
            if (!state.numeros) {
                return id
            }
            let gp = state.numeros.find(g =>
                g.id == id
            )
            return gp ? `${gp.id} - ${gp.phone}` : ''
        },
        getUserNumerosNameById: (state) => (id) => {
            if (!state.user_numeros) {
                return id
            }
            let gp = state.user_numeros.find(g =>
                g.id == id
            )
            return gp ? `${gp.id} - ${gp.name}` : ''
        },
        getNumeroSelect: (state) => {
            let gp = []
            state.numeros.forEach(element => {
                gp.push({ value: element.id, title: `${element.id} - ${element.phone}` })
            });
            return gp
        },
        getCampaignSelect: (state) => {
            let gp = []
            state.campaigns.forEach(element => {
                gp.push({ value: element.id, title: `${element.id} - ${element.name}` })
            });
            return gp
        },
    },
}