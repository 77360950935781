import axios from 'axios'
export default {
    state: {
        userFtp: [],
    },
    mutations: {
        setUsersFtp(state, payload) {
            state.userFtp = payload;
        },
    },
    actions: {
        setUsersFtp(context, payload) {
            context.commit('setUsersFtp', payload);
        },
        loadUsersFtp(context, payload) {
            let query = context.getters.getUtils.queryByObject(payload);
            axios(`ftpuser${query}`).then((resp) => {
                if (resp.status < 399) {
                    context.commit('setUsersFtp', resp.data);
                }
            });
        },
        saveUserFtp(context, payload) {
            // limpa o callback para não dar erro no axios
            const callback = typeof payload.callback == 'function' ? payload.callback : null;
            if(payload.callback) delete payload.callback;
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/ftpuser${finalUrl}`, payload).then((resp) => {
                if (resp.status < 399) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Usuário FTP salvo com Sucesso");
                    // context.dispatch('loadUsersFtp');
                    if(callback) callback(resp.data);
                }
            });
        },
        deletUserFtp(context, payload) {
            const callback = typeof payload.callback == 'function' ? payload.callback : null;
            axios.delete(`/ftpuser/${payload.id}`).then((resp) => {
                if(resp.status < 399){
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Usuário FTP Deletado com Sucesso");
                // context.dispatch('loadUsersFtp');
                if(callback) callback(resp.data);
                }
            });
        },
    },
    getters: {
        getUsersFtp(state) {
            return state.userFtp
        },
        getUserFtpNameById: (state) => (id) => {
            if (!state.userFtp) {
                return id
            }
            let gp = state.userFtp.find(g =>
                g.id == id
            )
            return gp ? `${gp.id} - ${gp.name}` : ''
        },
        getUserFtpById: (state) => (id) => {
            if (!state.userFtp) {
                return id
            }
            let gp = state.userFtp.find(g =>
                g.id == id
            )
            return gp || null;
        },
        getUserFtpSelect: (state) => {
            let gp = []
            state.userFtp.forEach(element => {
                gp.push({ value: element.id, title: `${element.id} - ${element.name}` })
            });
            return gp
        },
    },
}