import axios from 'axios'
export default {
    state: {
        notas: [],
        notasItems: [],
        notaDoacao: [],
    },
    mutations: {
        setNotas(state, payload) {
            state.notas = payload;
        },
        setNotasItems(state, payload) {
            state.notasItems = payload;
        },
        setNotaDoacao(state, payload) {
            state.notaDoacao = payload;
        },
    },
    actions: {
        setNotas(context, payload) {
            context.commit('setNotas', payload);
        },
        setNotasItems(context, payload) {
            context.commit('setNotasItems', payload);
        },
        setNotaDoacao(context, payload) {
            context.commit('setNotaDoacao', payload);
        },
        loadNotas(context) {
            axios(`notas`).then((resp) => {
                if (resp.status < 399) {
                    let its = []
                    context.commit('setNotas', resp.data);
                    resp.data.forEach(element => {
                        its =  [...its, ...element.items];
                    });
                    context.dispatch('setNotasItems', its);
                }
            });
        },
        loadNotaDoacao(context, payload) {
            axios(`notas/doacoes/${payload}`).then((resp) => {
                if (resp.status < 399) {
                    context.commit('setNotaDoacao', resp.data);
                }
            });
        },
        saveNota(context, playload) {
            // limpa o callback para não dar erro no axios
            const callback = typeof playload.callback == 'function' ? playload.callback : null;
            if(playload.callback) delete playload.callback;
            const metodo = playload.id ? "put" : "post";
            const finalUrl = playload.id ? `/${playload.id}` : "";
            axios[metodo](`/notas${finalUrl}`, playload).then((resp) => {
                if (resp.status < 399) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Nota salva com Sucesso");
                    context.dispatch('loadNotas');
                    if(callback) callback(resp.data);
                }
            });
        },
    },
    getters: {
        getNotas(state) {
            return state.notas
        },
        getNotasItems(state) {
            return state.notasItems
        },
        getNotaDoacao(state) {
            return state.notaDoacao
        },
        getNotaNameById: (state) => (id) => {
            if (!state.notas) {
                return id
            }
            let gp = state.notas.find(g =>
                g.id == id
            )
            return gp ? `${gp.invoiceNumber}` : ''
        },
        getNotaItemNameById: (state) => (id) => {
            if (!state.notasItems) {
                return id
            }
            let gp = state.notasItems.find(g =>
                g.id == id
            )
            return gp ? `${gp.name}` : ''
        },
        getNotaItemValueById: (state) => (id) => {
            if (!state.notasItems) {
                return id
            }
            let gp = state.notasItems.find(g =>
                g.id == id
            )
            return gp ? gp.value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL"
              }) : 0
        },
        getNotaItemById: (state) => (id) => {
            if (!state.notasItems) {
                return id
            }
            let gp = state.notasItems.find(g =>
                g.id == id
            )
            return gp ? gp : null;
        },
        getNotaById: (state) => (id) => {
            if (!state.notas) {
                return id
            }
            let gp = state.notas.find(g =>
                g.id == id
            )
            return gp || null;
        },
        getNotaSelect: (state) => {
            let gp = []
            state.notas.forEach(element => {
                gp.push({ value: element.id, title: `${element.invoiceNumber} - R$${element.totalAmount}` })
            });
            return gp
        },
    },
}