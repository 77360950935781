import axios from 'axios'
import router from '@/router'

export default {
  state: {
    users: [],
  },
  mutations: {
    setUser(state, payload) {
      state.users = payload;
    },
  },
  actions: {
    setUser(context, payload) {
      context.commit('setUser', payload);
    },
    loadUser(context, payload) {
      let query = context.getters.getUtils.queryByObject(payload);
      axios(`user/${query}`).then((resp) => {
        context.commit('setUser', resp.data);
      });
    },
    saveUser(context, payload) {
      // limpa o callback para não dar erro no axios
      const callback = typeof payload.callback == 'function' ? payload.callback : null;
      if (payload.callback) delete payload.callback;
      const metodo = payload.id ? "put" : "post";
      const finalUrl = payload.id ? `/${payload.id}` : "";
      axios[metodo](`/user${finalUrl}`, payload).then((resp) => {
        if (resp.status < 399) {
          context.dispatch("resetSnack");
          context.dispatch("showSuccessSnack", "Usuário salvo com Sucesso");
          if (callback) callback(resp.data);
        }
      });
    },
    editUser(context, payload) {

      axios.putForm(`user/${payload.id}`, payload, { headers: { 'Content-Type': 'multipart/form-data' } }).then((resp) => {
        if (resp.status < 400) {
          router.go(-1);
          axios(`user/${payload.id}`).then((resp) => {
            context.commit('setUser', resp.data)
          })
        }
      })
    },
    deleteUser(context, payload) {
      const callback = typeof payload.callback == 'function' ? payload.callback : null;
      axios.delete(`/user/${payload.id}`).then((resp) => {
        if (resp.status < 399) {
          context.dispatch("resetSnack");
          context.dispatch("showSuccessSnack", "Usuário Deletado com Sucesso");
          if (callback) callback(resp.data);
        }
      });
    }
  },
  getters: {
    getUsers(state) {
      return state.users
    },
    getUsersSelect(state) {
      return state.users.map((item) => {
        return {
          value: item.id,
          title: `${item.id} - ${item.name}`
        }
      })
    },
    getUserNameById: (state) => (id) => {
      if (!state.users) {
        return id
      }
      let gp = state.users.find(g =>
        g.id == id
      )
      return gp ? `${gp.id} - ${gp.name}` : ''
    }
  },
}