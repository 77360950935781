import { io } from "socket.io-client";
import axios from "../../plugins/axios";
export default {
    state: {
        socket: null,
        statusWB: false,
        phonesInfo: []
    },
    mutations: {
        connect(state, payload) {
            if (process.env.NODE_ENV === 'production') {
                state.socket = io({ auth: { token: payload } });
            } else {
                let url = axios.defaults.baseURL.replace('/api/', '');
                state.socket = io(url, { auth: { token: payload } });
            }
            state.socket.on('authorized', (data) => {
                state.phonesInfo = data;
            });
        },
        disconnect(state) {
            state.socket.disconnect();
        },
        setStatus(state, payload) {
            state.statusWB = payload;
        },
    },
    actions: {
        connect(context, payload) {
            context.commit('connect', payload)
        },
        disconnect(context) {
            context.commit('disconnect')
        },
        setStatus(context, payload) {
            context.commit('setStatus', payload)
        },
    },
    getters: {
        getSocket(state) {
            return state.socket
        },
        getSocketStatus(state) {
            return state.statusWB
        },
        getPhonesInfo(state) {
            return state.phonesInfo
        }
    },
}