export default {
  state: {
    rules: {
      required: value => !!value || 'Este campo é obrigatório',
      email: value => {
        if (!value) return true;
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'E-mail inválido'
      },
      max5: value => value && value.length <= 5 || 'Máximo de 5 caracteres',
      value1to8: value => value >= 1 && value <= 8 || 'O valor deve ser entre 1 e 8',
      validaCPF: value => validarCPF(value) || 'CPF inválido',
      validaData: value => {
        const dia = value.substring(0, 2)
        const mes = value.substring(3, 5)
        const ano = value.substring(6, 10)
        let data = new Date(ano, mes - 1, dia)
        if (data.getFullYear() < 1900) return 'Data inválida' 
        if (data.getFullYear() > new Date().getFullYear()) return 'Data inválida maior que o ano atual'
        return data instanceof Date && !isNaN(data) || 'Data inválida'
      },
      validaIp: value => {
        if (!value) return true;
        value = '192.168.' + value;
        const pattern = /^((1?\d{1,2}|2([0-4]\d|5[0-5]))\.){3}(1?\d{1,2}|2([0-4]\d|5[0-5]))$|^$/
        return (pattern.test(value) && value.length <= 15 ) || 'IP inválido'
      },
      validaPorta: value => {
        if (!value) return true;
        const pattern = /^[0-9]+$/
        return (pattern.test(value) && value.length <= 5 && value.length > 1) || 'Porta inválida.'
      },
      isNumber: value => {
        if (!value) return true;
        if (value < 0) return 'O número deve ser positivo'
        const pattern = /^[0-9]+$/
        return pattern.test(value) || 'Este campo deve ser numérico'
      },
      dontRepeatCan: (arr, value) => {
        if (!value) return true;
        if (arr.filter(x => x.indice === value).length > 1) return 'Índice já utilizado neste Módulo Guarita'
        return true
      },
      reqFieldMinLength: (len, value) => {
        if(value === undefined) return "Obrigatório.";
        if(value === null) return "Obrigatório.";
        if(value === "") return "Obrigatório.";
        return (value || '').length >= len || `Min ${len} carateres.`;
      },
      reqFieldMinMaxLength: (min,max, value) => {
        if(value === undefined) return "Obrigatório.";
        if(value === null) return "Obrigatório.";
        if(value === "") return "Obrigatório.";
        if(value.length >= min) return `Min ${min} carateres.`;
        if(value.length <= max) return `Max ${max} carateres.`;
        return true;
      },
    },
    utils: {
      formataData(dataTemp) {
        let data = new Date(dataTemp)
        data.setHours(data.getHours() - 3);
        return data.toISOString().substring(0, 16)
      },
      removeCaracteresEspeciais(cpf) {
        return cpf.replace(/[^\d]+/g, "");
      },
      mascaraData(data) {
        if (data.length > 10) {
          data = data.substring(0, 10)
        }
        return data.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3')
      },
      queryByObject(obj) {
        let query = '?'
        for (let key in obj) {
          if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '') {
            query += `${key}=${obj[key]}&`
          }
        }
        return query.substring(0, query.length - 1);
      },
    }
  },
  getters: {
    getRules(state) {
      return state.rules
    },
    getUtils(state) {
      return state.utils
    },
  },
}

function validarCPF(strCPF) {
  if (!strCPF) return true;
  let soma = 0;
  if (strCPF === "00000000000") return false;
  for (let i = 1; i <= 9; i++) {
    soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  }
  let resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++) {
    soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  }
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(10, 11))) return false;

  if (strCPF.length > 11) return false;

  return true;
}