import { createStore } from 'vuex'
import axios from 'axios'
import router from '../router/index'
import snackbar from './modules/snackbar'
import grupo from './modules/grupos'
import menu from './modules/menu'
import item_categoria from './modules/item_categoria'
import moradores from './modules/moradores'
import usuario from './modules/usuario'
import utils from './modules/utils'
import planos from './modules/planos'
import ftp from './modules/ftp'
import funcionarios from './modules/funcionarios'
import relatorios from './modules/relatorios'
import socketio from './modules/socketio'
import whatsapp from './modules/whatsapp'
import notas from './modules/notas'

export default createStore({
    state: {
        user: {},
        token: null,
        logged: false,
        targetUrl: null,
    },
    mutations: {
        setPermissoes(state, payload) {
            state.permissoes = payload
        },
        setLogged(state, payload) {
            state.logged = payload
        },
        setUser(state, payload) {
            state.user = payload
            state.logged = true
        },
        setToken(state, payload) {
            if(payload == null) return state.token = null
            state.logged = true;
            state.token = payload;
            axios.defaults.headers.common['Authorization'] = `${payload}`;
            localStorage.setItem('FTP_TOKEN', payload);
            let data = payload.split('.')
            state.user = JSON.parse(atob(data[1]))
        },
        destroyUser(state) {
            state.logged = false
            state.user = {}
            delete axios.defaults.headers.common['Authorization']
            localStorage.removeItem('FTP_TOKEN')
            router.push('/login')
        },
        setTargetUrl(state, payload) {
            state.targetUrl = payload
        },
    },
    actions: {
        logOff(context) {
            this.state.logged = false
            delete axios.defaults.headers.common['Authorization']
            localStorage.removeItem('FTP_TOKEN')
            context.commit('setLogged', false)
            context.commit('destroyUser', null)
        },
        setLogged(context, payload) {
            context.commit('setLogged', payload)
        },
        setTargetUrl(context, payload) {
            context.commit('setTargetUrl', payload)
        },
    },
    getters: {
        getUsuario(state) {
            return state.user
        },
        getToken(state) {
            return state.token
        },
        getLogged(state) {
            return state.logged
        },
        getDefaultRoute(state) {
            return state.user.route
        },
        getAppBarTitle(){
            return process.env.VUE_APP_BAR_TITLE;
        },
        getTargetUrl(state){
            return state.targetUrl;
        }
    },
    modules: {
        snackbar,
        grupo,
        menu,
        item_categoria,
        moradores,
        usuario,
        utils,
        planos,
        ftp,
        funcionarios,
        relatorios,
        socketio,
        whatsapp,
        notas,
    }
})