import axios from 'axios'
export default {
    state: {
        items: [],
        itemsFull: [],
        categorias: [],
    },
    mutations: {
        setItems(state, payload) {
            state.items = payload;
        },
        setItemsFull(state, payload) {
            state.itemsFull = payload;
        },
        setCategorias(state, payload) {
            state.categorias = payload;
        },
    },
    actions: {
        setItems(context, payload) {
            context.commit('setItems', payload);
        },
        setItemsFull(context, payload) {
            context.commit('setItemsFull', payload);
        },
        setCategorias(context, payload) {
            context.commit('setCategorias', payload);
        },
        loadItems(context) {
            axios(`items`).then((resp) => {
                if (resp.status < 399) {
                    context.commit('setItems', resp.data);
                }
            });
        },
        loadCategorias(context) {
            axios(`categorias`).then((resp) => {
                if (resp.status < 399) {
                    context.commit('setCategorias', resp.data);
                }
            });
        },
        loadItemsFull(context) {
            axios(`items/full`).then((resp) => {
                if (resp.status < 399) {
                    context.commit('setItemsFull', resp.data);
                }
            });
        },
        saveCategoria(context, playload) {
            // limpa o callback para não dar erro no axios
            const callback = typeof playload.callback == 'function' ? playload.callback : null;
            if(playload.callback) delete playload.callback;
            const metodo = playload.id ? "put" : "post";
            const finalUrl = playload.id ? `/${playload.id}` : "";
            axios[metodo](`/categorias${finalUrl}`, playload).then((resp) => {
                if (resp.status < 399) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Categoria salvo com Sucesso");
                    context.dispatch('loadCategorias');
                    if(callback) callback(resp.data);
                }
            });
        },
        saveItem(context, playload) {
            // limpa o callback para não dar erro no axios
            const callback = typeof playload.callback == 'function' ? playload.callback : null;
            if(playload.callback) delete playload.callback;
            const metodo = playload.id ? "put" : "post";
            const finalUrl = playload.id ? `/${playload.id}` : "";
            axios[metodo](`/items${finalUrl}`, playload).then((resp) => {
                if (resp.status < 399) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Item salvo com Sucesso");
                    context.dispatch('loadItems');
                    if(callback) callback(resp.data);
                }
            });
        },
    },
    getters: {
        getItems(state) {
            return state.items
        },
        getItemsFull(state) {
            return state.itemsFull
        },
        getCategorias(state) {
            return state.categorias
        },
        getLastOrderItem (state) {
            return state.items.length > 0 ? state.items[state.items.length - 1].orderBy + 1 : 1
        },
        getLastOrderCategoria (state) {
            return state.categorias.length > 0 ? state.categorias[state.categorias.length - 1].orderBy + 1 : 1
        },
        getItemNameById: (state) => (id) => {
            if (!state.items) {
                return id
            }
            let gp = state.items.find(g =>
                g.id == id
            )
            return gp ? `${gp.name}` : ''
        },
        getCategoriaNameById: (state) => (id) => {
            if (!state.categorias) {
                return id
            }
            let gp = state.categorias.find(g =>
                g.id == id
            )
            return gp ? `${gp.id} - ${gp.name}` : ''
        },
        getCategoriaSelect: (state) => {
            let gp = []
            state.categorias.forEach(element => {
                gp.push({ value: element.id, title: `${element.id} - ${element.name}` })
            });
            return gp
        },
    },
}