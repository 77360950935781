<template>
  <v-app>
    <Menu></Menu>
    <AppBar />
    <v-main>
      <v-container fluid>
        <router-view></router-view>
        <v-snackbar v-model="snck.snackbar" :color="snck.color" :timeout="snck.timeout">
          {{ snck.text }}
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Menu from "./components/Menu";
import AppBar from "./components/AppBar";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    AppBar,
    Menu,
    
  },
  computed: { ...mapGetters(["snackbar"]), ...mapGetters(["getLogged"]) },
  watch: {
    snackbar() {
      this.snck = this.snackbar;
    },
    getLogged(){
      if(this.getLogged){
        this.$store.dispatch('connect', this.$store.getters.getToken)
      }
    }
  },
  data: () => ({
    snck: {},
    showMenu: false,
  }),
  methods: {
  },
  created() {
    document.title = process.env.VUE_APP_TITLE;
    this.$store.dispatch('setTargetUrl', window.location.pathname);
  },
};
</script>
<style>
.v-main,
.v-container {
  display: flex;
  flex-direction: column;
}
.v-main {
  min-height: calc(100vh - 64px);
}
.v-container {
  flex-grow: 1;
}
.menu {
  background: #005f36 !important;
  color: rgb(223, 223, 223) !important;
}
.titleCard {
  background: #005f36 !important;
  color: rgb(223, 223, 223) !important;
}
.v-field {
  background-color: #FFF !important;
}
.primary-card {
  background-color: #F5F5F5 !important;
}
.secondary-card {
  background-color: #EEEEEE !important;
}
.primary-card .v-card-title,
.secondary-card .v-card-title,
.third-card .v-card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFF !important;
  background-color: rgb(var(--v-theme-primary), 0.75);
}

.v-table,
.v-table th {
  background-color: transparent !important;
}

.floating-buttom {
  position: fixed;
  bottom: 25px;
  right: 25px;
}

</style>