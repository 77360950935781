<template>
  <v-app-bar app dark color="primary">
    <v-toolbar-title
      ><v-avatar :image="logo" @click="$router.push({ path: '/' })"></v-avatar><span class="ml-2">STV Segurança - {{ $store.getters.getAppBarTitle }}</span>
    </v-toolbar-title>
    <v-btn v-if="getLogged" :ripple="false" size="large"><v-avatar :image="user.picture ? user.picture : noProfile"></v-avatar>{{ user.name }}
      <v-menu activator="parent">
        <v-list>
          <v-list-item key="editar" @click="editUser">
            <v-list-item-title
              ><v-icon>mdi-account</v-icon> Editar Usuário</v-list-item-title
            >
          </v-list-item>
          <v-list-item key="sair" @click="logOff">
            <v-list-item-title
              ><v-icon>mdi-logout-variant</v-icon> Sair</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-btn>
    <v-btn v-else  size="large" @click="$router.push({ path: '/login' })" >
      <v-icon class="mr-2">mdi-key</v-icon> Entrar
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "AppBar",
  data: () => {
    return {
      showMenu: false,
      noProfile: require("@/assets/no-profile.png"),
      logo: require("@/assets/logo.png"),
    };
  },
  computed: {
    ...mapState(["user", "grupos", "user"]),
    ...mapGetters(["getUsuario", "getLogged"]),
    workGroup: {
      get() {
        return this.$store.state.workGroup;
      },
      set(value) {
        this.$store.dispatch("setWorkGroup", value);
      },
    },
  },
  methods: {
    ...mapActions(["logOff", "setWorkGroup"]),
    getGrupos() {
      this.$http("grupo").then((resp) => {
        let grupos = resp.data;
        grupos.forEach((element) => {
          element.text = `${element.id} - ${element.name}`;
          element.value = element.id;
        });
        this.$store.commit("setGrupos", grupos);
      });
    },
    attMenu() {
      this.$store.dispatch("toogleMenu");
    },
    goHome() {
      this.$router.push({ path: "/clientes" });
    },  
    editUser() {
      this.$router.push({ path: "/usuario" });
    },  
  },
  watch: {
    getLogged() {
      if (this.getLogged) {
        this.showMenu = true;
        
      } else {
        this.showMenu = false;
      }
    },
  },
};
</script>
<style>

</style>
