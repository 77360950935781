<template>
  <v-navigation-drawer
    color="primary"
    app
    class="d-print-none menu-lateral"
    expand-on-hover
    rail
    v-if="getLogged"
    permanent
    >
    <v-list nav dense class="over">
      <v-list-item
        link
        :to="menuItem.to"
        v-for="menuItem in getMenu"
        :key="menuItem.name"
        :prepend-icon="menuItem.icon"
      >
        <v-list-item-title>{{ menuItem.name }}</v-list-item-title>
      </v-list-item>
      <v-spacer></v-spacer>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "MenuComponent",
  data: () => {
    return {
      menuItems: [],
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters([
      "getPermission",
      "getUsuario",
      "getLogged",
      "getMenu",
    ]),
  },
  watch: {},
};
</script>

<style scoped>
</style>